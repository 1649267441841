import React from "react";
import {
  Navigation,
  Route,
  Screen,
  Link,
  scale,
  glideIn,
  glideOut,
  drop,
  flip,
  glide,
} from "react-tiger-transition";

const work = () => {
  return (
    <>
      <div className="image_block">
        <Link to="/ourWebDevelopmentPortfolio" transition="flip-left">
          <div className="image">
            <img className="image__img" src="/assets/f1.jpg" alt="Bricks" />
            <div className="image__overlay image__overlay--primary">
              <div className="image__btn">Learn Pal</div>
              <p className="image__description">
                This is Photoshop's version of Lorem Ipsum. Proin gravida nibh.
                This is Photoshop's version of Lorem Ipsum. Proin gravida nibh.
              </p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default work;
