import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MetaData from "../components/MetaData";
import "../css/about.css";
import {
  Navigation,
  Route,
  Screen,
  Link,
  scale,
  glideIn,
  glideOut,
  drop,
  flip,
  glide,
} from "react-tiger-transition";
import Navbar from "../components/navbar";
import CAbout from "../components/cAbout";

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "white",
  },
}));

const About = () => {
  const classes = useStyles();
  return (
    <Screen className={classes.screen}>
      <MetaData title="creativebond - about" />
      <Navbar />
      <div className="about_section">
        <div className="title">about us</div>
        <div className="c-section">
          <CAbout
            imgsrc="/assets/icon1.png"
            atitle="OPTIMIZED FOR SPEED"
            apara="All our Themes are optimized for speed, providing you with quick loading website that will give you advantage while you are ranking your website on search engine."
          />
          <CAbout
            imgsrc="/assets/icon2.png"
            atitle="HIGHLY CUSTOMIZABLE"
            apara="Our Theme are easy to customize and make changes. You can make your own unique website using our WordPress Business One Page Theme."
          />
          <CAbout
            imgsrc="/assets/icon3.png"
            atitle="DEDICATED SUPPORT"
            apara="We provide efficient and dedicated Support to our customers.  Customer service is the provision of service to customers before, during and after a purchase."
          />
        </div>
      </div>
    </Screen>
  );
};

export default About;
