import React from "react";
import "../css/featuredwork.css";
import {
  Navigation,
  Route,
  Screen,
  Link,
  scale,
  glideIn,
  glideOut,
  drop,
  flip,
  glide,
} from "react-tiger-transition";
import Work from "./work";

const Featuredwork = () => {
  return (
    <div className="featured-work">
      <h2>featured work</h2>
      <Work />
      <Link to="/ourportfolio" className="more-client" transition="flip-left">
        view our work
      </Link>
    </div>
  );
};
export default Featuredwork;
