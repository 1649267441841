import React, { useState, useEffect } from "react";
import "../../css/ui_ux_design.css";
import MetaData from "../../components/MetaData";
import { makeStyles } from "@material-ui/core/styles";
import {
  Navigation,
  Route,
  Screen,
  Link,
  scale,
  glideIn,
  glideOut,
  drop,
  flip,
  glide,
} from "react-tiger-transition";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { useAlert } from "react-alert";
import OrderForm from '../../components/orderform';
import Portfolio from '../../components/portfolio';

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#03a5db",
    overflowY: "hidden",
  },
  btn: {
    background: "linear-gradient(45deg, #03a5db 30%, #03a5db 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    marginBottom: "10px",
  },
}));
const renderArrowPrev = (prop) =>{
    return (
    <>
    <img src="/assets/arrow-prev.png" alt="Prev" className="slidearrow" onClick={prop.clickHandler}/>
    </>        
    );
}
const renderArrowNext = (prop) =>{
    return (
    <>
    <img src="/assets/arrow-next.png" alt="Next" className="slidearrow"  onClick={prop.clickHandler}/>
    </>        
    );
}

const Wordpress = () => {
  const classes = useStyles();
  const alert = useAlert();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Screen className={classes.screen}>
        <MetaData title="creativebonds - ui-ux-design" />
        <div className="tops">
          <div className="left">
            <p>Portfolio</p>&nbsp;
            <h2>Wordpress Websites</h2>
          </div>
          <div className="mid">
            <div className="ui_c_btn" onClick={() => setOpen(true)}>
              I want a cool App
            </div>
          </div>
        </div>
        <Carousel
          autoPlay={false}
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          speed={500}
          infiniteLoop={true}

        >
         

    <Portfolio />
    <Portfolio />      
          
        </Carousel>
        <div className="arrow_btn">
          <Link to="/website-design-and-development" transition="flip-right">
            <i className="fa-solid fa-angle-up"></i>
          </Link>
        </div>
      </Screen>
      {/* model start */}
      <OrderForm open={open} handleClose={handleClose}/>
    </>
  );
};

export default Wordpress;
