import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import {
  Navigation,
  Route,
  Link
} from "react-tiger-transition";

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#03a5db",
    overflowY: "hidden",
  },
  btn: {
    background: "linear-gradient(45deg, #03a5db 30%, #03a5db 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    marginBottom: "10px",
  },
}));

const OrderForm = (props) => {
    const classes = useStyles();
      return (
    <>
<Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="model_main">
          <Typography
            className="display_flex"
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            <span className="arrow_none">
              <Link to="/" transition="flip-left">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
            </span>
            Contact Us
          </Typography>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              id="outlined-adornment-amount"
              label="Full Name"
              variant="outlined"
            />
          </FormControl>
          <br />
          <br />
          <FormControl>
            <TextField
              id="outlined-adornment-amount"
              label="Company"
              variant="outlined"
              className="field"
            />
          </FormControl>
          &nbsp; &nbsp;
          <FormControl>
            <TextField
              id="outlined-adornment-amount"
              label="Website"
              variant="outlined"
              className="field"
            />
          </FormControl>
          <br />
          <br />
          <FormControl>
            <TextField
              id="outlined-adornment-amount"
              label="Email"
              variant="outlined"
              className="field"
            />
          </FormControl>
          &nbsp; &nbsp;
          <FormControl>
            <TextField
              id="outlined-adornment-amount"
              label="Phone"
              variant="outlined"
              className="field"
            />
          </FormControl>
          <br />
          <br />
          <FormControl>
            <TextField
              id="outlined-adornment-amount"
              label="Budget"
              variant="outlined"
              className="field"
            />
          </FormControl>
          &nbsp; &nbsp;
          <FormControl>
            <TextField
              id="outlined-adornment-amount"
              label="I Am Intrested In"
              variant="outlined"
              className="field"
            />
          </FormControl>
          <br />
          <br />
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              id="outlined-adornment-amount"
              label="Your Message"
              variant="outlined"
            />
          </FormControl>
          <br />
          <br />
          <FormControl fullWidth sx={{ m: 1 }}>
            <Button
              onClick={() => {
                alert.success("clicked");
              }}
              className={classes.btn}
              variant="contained"
            >
              Submit
            </Button>
          </FormControl>
        </Box>
      </Modal>
      </>
      )
  }
  
  export default OrderForm;