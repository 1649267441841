import React from "react";
import "../css/about.css";

const CAbout = (props) => {
  return (
    <>
      <div className="category2">
        <img src={props.imgsrc} className="category_icon" />
        <h1>{props.atitle}</h1>
        <p>{props.apara}</p>
      </div>
    </>
  );
};

export default CAbout;
