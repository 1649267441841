import React, { useState, useEffect } from "react";         
const Portfolio = (props) => {   
      return (
    <>
         <div className="wordpressportfolio">
            <div className="w_main">
              <div className="internal_scrolling_w">
                <img src="/assets/portfolio.jpg" className="w__img" />
                <img src="/assets/portfolio.jpg" className="w__img" />
              </div>
              <div className="right_info">
                <h1>LiveResume </h1>
                <p>Wordpress website design and development </p>
                <p>
                  “Responsive web design is becoming more important is amount
                  Icons are also appropriate for toggle buttons that allow a
                  single choice to be selected or deselected, such as adding or
                  removing a star to an item such as adding or removing a star
                  to an item“.
                </p>
              </div>
            </div>
          </div>
          </>
  );
};
export default Portfolio;